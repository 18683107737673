import EXIF from 'exif-js';
import { forEach } from 'lodash-es';

/**
 * 获取文字
 * @param {string} origin
 * @return {string}
 */
export const getValue = function(origin) {

    // 表情图片换成 [表情]
    let value = replaceEmojiToString(origin);

    // 连续的 <br/> 合并成一个，并换成 \n
    value = value.replace(/(<br\/?>)+/g, '\n');

    // 空格实体符换成空格
    value = value.replace(/&nbsp;/g, ' ');

    return value.trim();

};

/**
 * 表情图片转文字
 * @param {string} origin
 * @return {string}
 */
const replaceEmojiToString = function (origin) {

    const div = document.createElement('div');

    div.innerHTML = origin;

    forEach(div.querySelectorAll('img'), (img) => {
        const { title } = img;
        img.replaceWith(title);
    });

    return div.innerHTML;

};

/**
 * 旋转图片
 * @param options
 * @constructor
 */
export const ImageFileRotator = function(options = {}) {
    if (typeof options.maxSize === 'undefined') options.maxSize = 1500;
    if (typeof options.jpegQuality === 'undefined') options.jpegQuality = 0.7;

    let image; // Image object (<img>)
    let imageData; // Image from canvas as byte array

    /**
     * Set up operations and degrees to rotate for each EXIF orientation (index).
     */
    let ExifOrientations = [
        { op: 'none', degrees: 0 },
        { op: 'flip-x', degrees: 0 },
        { op: 'none', degrees: 180 },
        { op: 'flip-y', degrees: 0 },
        { op: 'flip-x', degrees: 90 },
        { op: 'none', degrees: 90 },
        { op: 'flip-x', degrees: -90 },
        { op: 'none', degrees: -90 }
    ];

    /**
     * Converts a base64 string to byte array.
     */
    function base64toBlob(base64Data, contentType = '', sliceSize = 512) {

        let byteCharacters = atob(base64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);
            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }

    /**
     * Determines whether we need to change width/height dimensions or not.
     */
    function isRotated(orientation) {
        // eslint-disable-next-line no-implicit-coercion
        return !!~[5, 6, 7, 8].indexOf(orientation);
    }

    function flipContext(ctx, canvas, x, y) {
        ctx.translate(x ? canvas.width : 0, y ? canvas.height : 0);
        ctx.scale(x ? -1 : 1, y ? -1 : 1);
    }

    function rotateContext(ctx, attr) {
        let x = attr.x || 0;
        let y = attr.y || 0;

        if (attr.degrees) {
            attr.radians = attr.degrees * (Math.PI / 180);
        }

        ctx.translate(x, y);
        ctx.rotate(attr.radians);
        ctx.translate(-x, -y);
    }

    function calculateSize(image, maxSize) {
        let size = { width: image.width, height: image.height };
        if (image.width > maxSize || image.height > maxSize) {
            let ratio = image.width / image.height;
            if (image.width >= image.height) {
                size.width = maxSize;
                size.height = maxSize / ratio;
            } else {
                size.height = maxSize;
                size.width = maxSize * ratio;
            }
        }
        return size;
    }

    function setDimensions(canvas, size, orientation) {
        if (isRotated(orientation)) {
            canvas.setAttribute('height', size.width);
            canvas.setAttribute('width', size.height);
        } else {
            canvas.setAttribute('width', size.width);
            canvas.setAttribute('height', size.height);
        }
    }

    function drawOnCanvas(image, originCanvas, orientation, maxSize) {
        let canvas = originCanvas;
        let ctx = canvas.getContext('2d');

        let exifOrientation = ExifOrientations[orientation - 1];
        let size = calculateSize(image, maxSize);
        setDimensions(canvas, size, orientation);

        // Clear canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Flip vertically or horizontally
        if (exifOrientation.op == 'flip-x') flipContext(ctx, canvas, true, false);
        if (exifOrientation.op == 'flip-y') flipContext(ctx, canvas, false, true);

        // Rotate image
        if (exifOrientation.degrees) {
            rotateContext(ctx, {
                degrees: exifOrientation.degrees,
                x: canvas.width / 2,
                y: canvas.height / 2
            });

            if (isRotated(orientation)) {
                let diff = canvas.width - canvas.height;
                ctx.translate(diff / 2, -diff / 2);
            }
        }

        ctx.drawImage(
            image,
            0,
            0,
            image.width,
            image.height, // Source rectangle
            0,
            0,
            size.width,
            size.height
        ); // Destination rectangle
    }

    function readImageToCanvasOnLoad(image, canvas, callback) {
        getExifOrientation(image, function(orientation) {
            drawOnCanvas(image, canvas, orientation, options.maxSize);
            if (callback) callback();
            else console.warn('No callback for readImageToCanvas');
        });
    }

    function getExifOrientation(image, callback) {
        if (!image) {
            console.warn('No image');
            return;
        }

        EXIF.getData(image, function() {
            let orientation = EXIF.getTag(image, 'Orientation') || 1;
            if (callback) callback(orientation);
            else console.warn('No callback for getExifOrientation()');
        });
    }

    return {
        /**
         * Run to initialize ImageFileRotator.
         */
        newImage: function() {
            imageData = null;
            image = new Image();
        },

        /**
         * Returns the image data if any file has been read.
         * @returns {Blob|null}
         */
        getImageData: function() {
            return imageData;
        },

        /**
         * Draw an image (<img>) or contents of a canvas to another canvas. The destination
         * canvas is resized properly.
         * @param source The image or source canvas to draw on a new canvas.
         * @param destination The destination canvas to draw onto.
         * @param maxSize Maximum width or height of the destination canvas.
         */
        copyToCanvas: function(source, destination, maxSize) {
            let size = calculateSize(source, maxSize);
            setDimensions(destination, size, 1);
            let destCtx = destination.getContext('2d');
            destCtx.drawImage(
                source,
                0,
                0,
                source.width,
                source.height,
                0,
                0,
                size.width,
                size.height
            );
        },

        /**
         * Draw an image from a file on a canvas.
         * @param file The uploaded file.
         * @param canvas The canvas object to draw on.
         * @param callback Function that is called when the operation has finished.
         */
        readImageToCanvas: function(file, canvas, callback) {
            this.newImage();
            if (!file) return;

            let reader = new FileReader();
            reader.onload = function(fileReaderEvent) {
                image.onload = function() {
                    readImageToCanvasOnLoad(this, canvas, callback);
                };
                image.src = fileReaderEvent.target.result; // The URL from FileReader
            };
            reader.readAsDataURL(file);
        },

        /**
         * Read the canvas data and save it as a binary byte array to image data variable.
         * Get this data using the method getImageData().
         * @param canvas
         */
        saveCanvasToImageData: function(canvas) {
            let base64 = canvas
                .toDataURL('image/jpeg', options.jpegQuality)
                .replace(/^data:image\/(png|jpeg|jpg|gif);base64,/, '');
            imageData = base64toBlob(base64, 'image/jpeg'); // Byte array
        }
    };
};

/**
 * 修正移动端的相片偏转
 * @param image
 * @param maxSize
 * @param jpegQuality
 */
export const fixImageRotate = function (image, maxSize = 4096, jpegQuality = 0.7) {

    return new Promise((resolve, reject) => {
        try {
            const uploader = new ImageFileRotator({
                maxSize: maxSize,
                jpegQuality: jpegQuality
            });

            const canvas = document.createElement('canvas');
            uploader.readImageToCanvas(image, canvas, function() {
                uploader.saveCanvasToImageData(canvas);
                resolve(uploader.getImageData());
            });
        } catch (error) {
            reject(error);
        }
    });

};
