import PropTypes from 'prop-types';

/* ID 的类型，有可能是字符串或者数组 */
const IDPropTypes = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

/* 评论的 JSON 结构约定 */
export const CommentPropTypes = {
  /* 评论ID */
  id: IDPropTypes,
  /* 评论者头像 */
  avatar_url: PropTypes.string,
  /* 评论者昵称 */
  nick_name: PropTypes.string,
  /* 评论者的用户ID */
  user_id: IDPropTypes,
  /* 评论的内容 */
  content: PropTypes.string,
  /* 评论中附带的图片 */
  images: PropTypes.array,
  /* 评论的点赞数 */
  like_count: PropTypes.number,
  /* 评论的发表时间 */
  created_at: PropTypes.string,
  /* 评论的发表时间，直接展示用 */
  time: PropTypes.string,
  /* 是否关闭评论 */
  disabled: PropTypes.bool,
  /* 是否管理员评论 */
  is_admin: PropTypes.bool,
  /* 是否已点赞 */
  is_liked: PropTypes.bool,

  location: PropTypes.string,

  /* 评论的回复对象 */
  parent: PropTypes.shape({
    id: IDPropTypes,
    nick_name: PropTypes.string,
    images: PropTypes.array,
  }),

  user: PropTypes.shape(),

  onUploadImage: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickLike: PropTypes.func,
  onClickMenu: PropTypes.func,
  onClickReport: PropTypes.func,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
};
