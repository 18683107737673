import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './image-pcquote.less';
const ImagePcquote = (props) => {
    const { imgList, handleImagesClick } = props;
    return (
        <div className="image_pcquote">
            {imgList.map((item, i) => (
                <span
                    key={i}
                    className={classNames({
                        pics_img: imgList.length !== 1,
                        pics_img_one: imgList.length === 1
                    })}
                    onClick={event => handleImagesClick(i)}
                >
                    <img className="pic_img" src={item.original_url} />
                </span>
            ))}
        </div>
    );
};

ImagePcquote.defaultProps = {
    imgList: [],
    original_url: '',
    handleImagesClick(i) { console.log('函数未绑定') }
};

ImagePcquote.propTypes = {
    imgList: PropTypes.array
};



export { ImagePcquote };