import React, { Component } from 'react';
import { isEmpty } from 'lodash-es';
import {
  getProductsTagsList,
  postListTagsAdd,
  postListTagsDel,
  postListTagsAllGet,
} from 'components/api';
import { TcIconTagPcliked } from 'components/IconSystem';
import message from '@tencent/ten-design-react/lib/message';
import PropTypes from 'prop-types';
import './style.less';

class TagChoose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagArr: [],
      contentArr: {},
    };
  }
  componentDidMount() {
    const tagArr = JSON.parse(JSON.stringify(this.props.tagArrList));
    this.setState({ tagArr });
    // this.getProductsTagsListApi(this.props.productId, 'admin_list'); // tag list
    this.postListTagsAllGetApi(this.props.productId, 'post', [...this.props.contentId]);
  }
  getProductsTagsListApi(productId, scene) {
    getProductsTagsList(productId, scene).then((res) => {
      this.setState({ tagArr: res.data.map((item, index) => ({ ...item, isSet: false })) }, () => {
        this.postListTagsAllGetApi(this.props.productId, 'post', [...this.props.contentId]);
      });
    });
  }
  postListTagsAllGetApi(productId, contentType, contentIds) {
    postListTagsAllGet(productId, contentType, contentIds).then((res) => {
      if (!isEmpty(res.data)) {
        this.setState({ contentArr: res.data[contentIds] }, () => {
          this.setDefaultTag();
        });
      }
    });
  }
  setDefaultTag() {
    const { state } = this;
    const tagArr = [...state.tagArr];
    const contentArr = [...state.contentArr.tags];
    tagArr.forEach((e) => {
      contentArr.forEach((t) => {
        if (e.id === t) e.isSet = true;
      });
    });
    this.setState({ tagArr });
  }
  async handleChangeTag(itemTag) {
    const { props, state } = this;
    const tagArr = [...state.tagArr];
    const apiFun = itemTag.isSet ? postListTagsDel : postListTagsAdd;
    await apiFun(props.productId, 'post', props.contentId, itemTag.id).then((res) => {
      if (res.data) {
        this.setState({
          tagArr: tagArr.map((item, index) => (item.id === itemTag.id ? { ...item, isSet: !itemTag.isSet } : item)),
        });
      }
    })
      .catch((error) => {
        message.error(error.message);
      });
    props.changePostsList(this.state.tagArr.filter(i => i.isSet === true), props.contentId, props.isTop);
  }

  render() {
    const { state, props } = this;
    const jumpHerf = `/dashboard/products/${props.productId}?tab=3`;
    const isAdminVisible = state.tagArr.find(i => i.visible === 'admin');
    const isAdminVisiblePublic = state.tagArr.filter(i => i.visible === 'all');
    const isAdminVisiblePrivate = state.tagArr.filter(i => i.visible === 'admin');
    return (
      <div className="tag-choose" style={{ width: props.width }}>
        <div className="tag-cont">
          {!isEmpty(state.tagArr)
                    && <>
                      {!isEmpty(isAdminVisible)
                        && <>
                          {!isEmpty(isAdminVisiblePublic) && <span className="tag-cont__label">公开标签</span>}
                          {isAdminVisiblePublic.map((item, index) => (
                            <div key={index} className="tag-cont__list"
                              role="button" tabIndex="0"
                              onClick={() => {
                                this.handleChangeTag(item);
                              }}
                              onKeyPress={() => {
                                this.handleChangeTag(item);
                              }}>
                              <span className="tag-cont__list--color" style={{ backgroundColor: item.setting.color }}></span>
                              <span className={`tag-cont__list--name ${item.isSet ? 'yesSet' : 'noSet'}`}>{item.name}</span>
                              {item.isSet && <TcIconTagPcliked className="tag-cont__list--icon" />}
                            </div>
                          ))}
                          {!isEmpty(isAdminVisiblePrivate) && <span className="tag-cont__label">仅管理员可见标签</span>}
                          {isAdminVisiblePrivate.map((item, index) => (
                            <div key={index} className="tag-cont__list"
                              role="button" tabIndex="0"
                              onClick={() => {
                                this.handleChangeTag(item);
                              }}
                              onKeyPress={() => {
                                this.handleChangeTag(item);
                              }}>
                              <span className="tag-cont__list--color" style={{ backgroundColor: item.setting.color }}></span>
                              <span className={`tag-cont__list--name ${item.isSet ? 'yesSet' : 'noSet'}`}>{item.name}</span>
                              {item.isSet && <TcIconTagPcliked className="tag-cont__list--icon" />}
                            </div>
                          ))}
                        </>
                      }
                      {isEmpty(isAdminVisible)
                        && <>
                          {state.tagArr.map((item, index) => (
                            <div key={index} className="tag-cont__list"
                              role="button" tabIndex="0"
                              onClick={() => {
                                this.handleChangeTag(item);
                              }}
                              onKeyPress={() => {
                                this.handleChangeTag(item);
                              }}>
                              <span className="tag-cont__list--color" style={{ backgroundColor: item.setting.color }}></span>
                              <span className={`tag-cont__list--name ${item.isSet ? 'yesSet' : 'noSet'}`}>{item.name}</span>
                              {item.isSet && <TcIconTagPcliked className="tag-cont__list--icon" />}
                            </div>
                          ))}
                        </>
                      }
                    </>
          }
          {isEmpty(state.tagArr)
            && <span className="tag-none">暂无标签，
              <span className="tag-manage__span tag-manage__span--herf" tabIndex="0" role="button"
                onClick={() => window.open(jumpHerf, '_self')}
                onKeyPress={() => window.open(jumpHerf, '_self')}>添加</span>
            </span>}
        </div>
        <div className="tag-manage">
          <span className="tag-manage__span tag-manage__span--manage" tabIndex="0" role="button"
            onClick={() => window.open(jumpHerf, '_self')}
            onKeyPress={() => window.open(jumpHerf, '_self')}>管理</span>
        </div>
      </div>
    );
  }
}
TagChoose.propTypes = {
  isTop: PropTypes.bool,
  width: PropTypes.number,
  productId: PropTypes.number,
  contentId: PropTypes.string,
  tagArrList: PropTypes.array,
  changePostsList: PropTypes.func,
};
TagChoose.defaultProps = {
  tagArrList: [],
};
export { TagChoose };
