/* eslint-disable react/prop-types, camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tag from 'components/tag';
import Icon from 'components/icon';
import RichText from 'components/rich-text';
import { ariaRole } from 'components/util/aria';
import { TcIconMore, TcIconLikes } from 'components/IconSystem';
import { href_profile, ceGroupName } from 'components/href-helper';
import { LazyImage } from 'components/lazy-image';
import { dangerouslySetInnerHTML } from 'components/render-rich-text';
import { ImageMobile } from 'components/comment-image';
import defaultImg from 'components/assets-image/user/default-avatar.png';

import './comment-mobile.less';

/**
 * 移动端评论
 */
const CommentMobile = (props) => {
  const { item, is_liked, showMoreBtn } = props;

  const [avatar, setAvatar] = useState(item.avatar_url);
  // 获取评论内容的朗读
  const getContentAria = () => {
    let quote = '';
    if (item.parent && item.parent.id !== 0) {
      const quoteName = `${item.parent.is_admin ? '管理员' : ''}${item.parent.nick_name} `;
      const quoteContent = item.parent.content;
      quote = `引用了 ${quoteName} 说的 ${quoteContent}`;
    }

    return `${item.content} ${quote}`;
  };

  const handleCommentClick = (e) => {
    if (e?.target?.nodeName === 'A') return;
    props.onClickItem(item.id, item.nick_name);
  };

  return (

    <Tag.li
      className="comment_item"
      onClick={handleCommentClick}
      tabIndex="0"
    >

      <Tag.a aria-hidden={true} href={href_profile(item.user_id)} className="user_pic" onClick={event => event.stopPropagation()}>
        <LazyImage src={avatar} onError={() => setAvatar(defaultImg)} className="comment_item__profile" />
      </Tag.a>

      <Tag.div className="right_cont">

        <Tag.div className="user_infor">
          <Tag.div className="user_infor_left">

            <Tag.span className="name" onClick={event => event.stopPropagation()}>
              <Tag.a
                className={classNames(item.is_admin ? 'admin' : '')}
                href={href_profile(item.user_id)}
                aria-label={`${item.is_admin ? '管理员' : ''}${item.nick_name} 前往${item.nick_name} 个人中心`}>
                {item.nick_name}
              </Tag.a>
            </Tag.span>

            <Tag.span className="messge">{item.time}</Tag.span>
            <Tag.span className="group_name">{ceGroupName(item.group_name, item.postname)}</Tag.span>
          </Tag.div>
          <Tag.div className="user_infor_right">

            <Tag.div
              className="action-area"
              onClick={(event) => {
                event.stopPropagation();
                // FIXME 这里不加setTimeout 会小程序导致点击穿透。有空研究下小程序事件代理
                setTimeout(() => {
                  props.onClickLike(item.id);
                }, 0);
              }}
              {...ariaRole('button')}
              tabIndex="0"
              aria-label={`${item.like_count}赞  ${is_liked ? '已点赞' : ''}`}
            >

              {is_liked
                ? (<Icon className="tcIcon_liked" type="liked-dark" />)
                : (<Icon className="tcIcon_likes" type="likes-light" />)
              }

              <Tag.span
                className={classNames('num', { active: is_liked })}
              >
                {item.like_count || 0}
              </Tag.span>

            </Tag.div>

            {showMoreBtn && (
              <Tag.button
                className="action-area"
                onClick={(evt) => {
                  evt.stopPropagation();
                  // FIXME 这里不加setTimeout 会小程序导致点击穿透。有空研究下小程序事件代理
                  setTimeout(() => {
                    props.onClickMore(item);
                  }, 0);
                }}
                {...ariaRole('button')}
                aria-label="更多操作"
              >
                <TcIconMore />
              </Tag.button>
            )}

          </Tag.div>
        </Tag.div>
        <Tag.div className="user_desc" >
          {item.parent && (
            <Tag.span className="desc_font" isText>
              回复<Tag.a isText href={href_profile(item.parent.user_id)} onClick={event => event.stopPropagation()} className={item.parent.is_admin ? 'desc_is_admin' : 'desc_is_user'}>{item.parent.nick_name || '用户'}</Tag.a>：
            </Tag.span>
          )}
          <RichText
            className="desc_font"
            isText
            content={dangerouslySetInnerHTML(item.content).__html}
            aria-label={getContentAria()}
            {...ariaRole('article')}
          />
          {
            (item.images && (item.images.length > 0)) && (
              <Tag.div className="desc_pic">
                <ImageMobile imgList={item.images} />
              </Tag.div>
            )
          }

        </Tag.div>

        {/* {item.parent && item.parent.id !== 0 && (
          <Tag.div className="user_quote" aria-hidden={true}>
            <Tag.a
              className={classNames('user_quote_title', {
                admin: item.parent.is_admin,
              })}
              href={href_profile(item.parent.user_id)}>
              {item.parent.nick_name}
            </Tag.a>
            <RichText
              className="user_quote_desc"
              content={dangerouslySetInnerHTML(item.parent.content).__html}
            />
          </Tag.div>
        )} */}
      </Tag.div>
    </Tag.li>

  );
};

CommentMobile.propsTypes = {
  onClickItem: PropTypes.func,
  onClickMore: PropTypes.func,
  onClickLike: PropTypes.func,
};

CommentMobile.defaultProps = {
  onClickItem: () => console.log('onClickItem 还未绑定'),
  onClickMore: () => console.log('onClickItem 还未绑定'),
  onClickLike: () => console.log('onClickLike 还未绑定'),
  showMoreBtn: true,
};

export { CommentMobile };
