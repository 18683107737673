
// 图片大小
export const IMG_MAX_SIZE = (8 - 0.1) * 1024 * 1024;

// 大小错误
export const IMG_ERR_SIZE = new Error('图片太大啦\n不要超过5M哦');

// 允许的图片类型
export const IMG_TYPE = /png|jpe?g|gif/i;

// 所有图片类型
export const IMG_FULL_TYPE = /png|jpg?g|gif|bmp|webp|svg|tiff/i;

// 类型错误
export const IMG_ERR_TYPE = new Error('图片格式错误啦\n仅支持[png/jpeg/gif]哦');

// 数量错误
export const IMG_ERR_COUNT = new Error('图片太多啦\n不要超过6张图哦');

// 重复图片
export const IMG_ERR_EXIST = new Error('这个图片已选择过啦\n请换张图片');

// 文字太少
export const VALUE_ERR_MIN = new Error('内容不能为空');

// 超出最大字符
export const VALUE_ERR_MAX = new Error('超出最大字数');

/**
 * 检查图片大小
 * @param img
 * @return {boolean}
 */
export function checkSize(img) {
  console.log(img);
  return img.size > ((8 - 0.1) * 1024 * 1024);
}

/**
 * 检查图片类型
 * @param img
 * @return {boolean}
 */
export function checkType(img) {
  const isImage = IMG_TYPE.test(img.type);
  return !isImage;
}

/**
 * 判断文字长度
 * @param text
 * @param length
 */
export function checkTextLength(text, length) {
  const textTrim = text.trim();

  if (textTrim.length > length) {
    return false;
  }

  if (textTrim.length <= 0) {
    return false;
  }

  return true;
}

/**
 * 递归查找第一个 form 父元素
 * @param element
 * @return {HTMLFormElement|boolean}
 */
export const findParentElement = function (element) {
  if (!element || !element.parentElement || element.parentElement === document.documentElement) {
    return false;
  }

  if (element.parentElement.tagName !== 'FORM') {
    findParentElement(element.parentElement);
  }

  return element.parentElement;
};
