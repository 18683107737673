import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImageAlbum } from '@tencent/coral-design';
import { dangerouslySetInnerHTML } from 'components/render-rich-text';
import { ImagePcquote } from 'components/comment-image';

export class CommentParent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_image_album: null,
    };
  }

  handleImagesClick(i) {
    this.setState({ show_image_album: i });
  }

  render() {
    const { state, props } = this;

    // todo 删除引用评论的样式有待确认
    if (this.props.id === 0) {
      return <div className="reply_quote">
        <div className="reply_quote_item">原评论已被删除</div>
      </div>;
    }

    return (
      <div className="reply_quote">
        <div className="reply_quote_item">
          <h5 className="title">{props.nick_name}</h5>
          <p className="desc" dangerouslySetInnerHTML={dangerouslySetInnerHTML(props.content)} />
          {(Boolean(props.images.length)) && (
            <div className="pics">
              <ImagePcquote imgList={props.images} handleImagesClick={i => this.handleImagesClick(i)} />
            </div>
          )}
        </div>

        {state.show_image_album !== null && (
          <ImageAlbum
            imageList={props.images.map(img => img.original_url)}
            previewList={props.images.map(img => img.thumb_url)}
            showPreview={true}
            showIndex={state.show_image_album}
            onClose={event => this.setState({ show_image_album: null })}
          />
        )}
      </div>
    );
  }
}

CommentParent.defaultProps = {
  id: '',
  nick_name: '',
  images: [],
  isReplyClick: () => console.log('点击回复按钮， 未设置回调')
};

CommentParent.propTypes = {
  id: PropTypes.number,
  nick_name: PropTypes.string,
  images: PropTypes.array,
  content: PropTypes.string,
};
