import debounce from 'lodash.debounce';

let foldTargetChecker;

let resizeWatchingCount = 0;

function getTargetChecker(el) {
  return new Promise((resolve, reject) => {
      foldTargetChecker ? resolve(foldTargetChecker) : reject(Error('init fold target'));
  }).catch(() => {
      if (!foldTargetChecker) {
          // 异步创建checker元素，避免组件初始化时dom抖动影响宽度判定
          const targetStyles = window.getComputedStyle(el, false);
          foldTargetChecker = document.createElement('div');
          foldTargetChecker.style.font = targetStyles.font;
          foldTargetChecker.style.lineHeight = targetStyles.lineHeight;
          foldTargetChecker.style.width = targetStyles.width;
          foldTargetChecker.style.wordBreak = targetStyles.wordBreak;
          foldTargetChecker.style.letterSpacing = targetStyles.letterSpacing;
          foldTargetChecker.style.position = 'fixed';
          foldTargetChecker.style.zIndex = -1;
          foldTargetChecker.style.opacity = 0;
          foldTargetChecker.style.top = '-9999px';
          foldTargetChecker.style.left = '-9999px';
          // 用来计算行高
          foldTargetChecker.__lineHeight = parseInt(targetStyles.lineHeight, 10);

          document.body.appendChild(foldTargetChecker);
      }

      return foldTargetChecker;
  });
}

// 窗口宽度变化，直接丢弃旧的检测元素
const resizeCallback = debounce(() => {
    if (foldTargetChecker) {
        document.body.removeChild(foldTargetChecker);
        foldTargetChecker = null;
    }
}, 500);

export function startResizeWatching() {
    if (!resizeWatchingCount) {
        window.addEventListener('resize', resizeCallback);
    }

    resizeWatchingCount++;
}

export function stopResizeWatching() {
    resizeWatchingCount--;

    if (!resizeWatchingCount) {
        window.removeEventListener('resize', resizeCallback);
    }
}

/**
 * 检查首页的产品帖子是否需要折叠展示
 * @param {string} content
 */
export function checkContentNeededFold(content, targetEl, maxLine = 6) {
    return getTargetChecker(targetEl).then((checker) => {
        // 暂时只处理换行
        checker.innerHTML = content.replace(/\n/g, '<br/>');

        // 允许半行的浮动
        return checker.clientHeight > checker.__lineHeight * (maxLine + 0.5);
    });
}