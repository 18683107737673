import React from 'react';
import PropTyps from 'prop-types';

export const ConfirmSpam = (props) => (
    <div className="confirm">
        <p className="confirm-text">加入黑名单后，该用户的发帖和回复都不会再显示，且无法参与反馈、评论任何问题。</p>
        <div className="confirm-btn">
            <button className="btn btn-primary" onClick={props.onConfirm}>确定</button>
            <button className="btn" onClick={props.onCancel}>取消</button>
        </div>
    </div>
);

ConfirmSpam.propTypes = {
    /**
     * 取消
     */
    onCancel: PropTyps.func,
    /**
     * 确认
     */
    onConfirm: PropTyps.func
};
