import React, { Fragment } from 'react';
import { Spinner } from 'components/spinner';

// todo 与 image-preview-container 合并为同一个组件
export const ImagePreview = (props) => {

    if (props.images.length === 0) {
        return null;
    }

    return (
        <div className="text_pic">

            {props.images.map((item, i) => (
                <div className="upload_pic" key={i}>

                    {item.progress < 100 && (
                        <div className="upload_pic_progress" style={{ 'backgroundColor': `rgba(244, 247, 250, ${(100 - item.progress) / 100})` }}>
                            <Spinner />
                            <span className="rate">{item.progress}%</span>
                        </div>
                    )}

                    <img className="upImg" src={item.src} />
                    <i className="close" onClick={event => props.onDel(item.id)}></i>
                </div>
            ))}

            <div className="count">({props.images.length}/{props.limit})</div>
        </div>
    );
};

ImagePreview.defaultProps = {
    images: [],
    limit: 6,
    onDel(id) {
        console.log(`onDel 未绑定, id:${id}`);
    }
};
