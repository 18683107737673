import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import { isNumber, uniqueId, isError } from 'lodash-es';
import PropTypes from 'prop-types';
import { TcIconPicLine } from 'components/IconSystem';
import { EmojiPopup } from 'components/emoji';
import { revertBr, revertEmojiTextToImage, revertWordToImage } from 'components/render-rich-text';
import { Editor, insertHtml } from 'components/editor';
import { SelectTopic } from 'components/select-topic';
import { Topic } from 'components/topic';
import { BtnEmojiWithDropdown } from './btn/btn-emoji';
import { ImagePreview } from './image-preview';
import {
  checkSize,
  checkType,
  findParentElement,
  IMG_ERR_COUNT,
  IMG_ERR_EXIST,
  IMG_ERR_SIZE,
  IMG_ERR_TYPE,
  VALUE_ERR_MIN,
  VALUE_ERR_MAX,
} from './schema';
import { getValue } from './utils';
import { getProductId } from 'components/util';
import './user-enter.less';

class UserEnter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: {},
      submitting: false,
    };
    this.productId = getProductId();
    this.editor = createRef();

    this.emojiRef = createRef();
    this.selectTopicRef = createRef();

    // 避免多个实例的时候，label for 出现重复，关联一个唯一id
    this.inputFileId = uniqueId('input-file-for-images');
  }

  componentWillUnmount() {
    this.willUnMount = true;
  }

  onSubmit(event) {
    const { state, props } = this;

    const value = this.getTextValue();
    if (value.length === 0) {
      props.onError(VALUE_ERR_MIN);
      return;
    }

    if (value.length > props.limitText) {
      props.onError(VALUE_ERR_MAX);
      return;
    }

    const images = Object.keys(state.images).map((id) => state.images[id].respInfo);
    this.setState({ submitting: true });
    const onSuccess = (isSubmit = true) => {
      if (isSubmit) {
        this.onReset();
      } else {
        this.setState({ submitting: false });
      }
    };

    const onError = (error) => {
      this.setState({ submitting: false });
    };

    let topic = null;
    // 如果产品打开了话题选择， 还需要获取话题值
    if (props.showSelectTopic) {
      topic = this.getTopicValue();
    }
    props
      .onSubmit(value, images, topic)
      .then(onSuccess)
      .catch(onError);
  }

  getTopicValue = () => this.selectTopicRef.current?.getActiveTopic();

  onReset() {
    // 回复评论成功后，评论会 unMount 掉输入框，
    // 如果是即将 unMount 状态，不需要再手动清除状态
    if (this.willUnMount) {
      return;
    }

    this.setState({
      images: {},
      submitting: false,
    });

    if (this.editor.current) {
      this.editor.current.reset();
    }
  }

  /**
   * 选择表情
   * @param text
   */
  onSelectEmoji(text) {
    if (!this.editor.current) {
      // console.log('！Editor Ref 不存在');
      return;
    }

    const img = revertEmojiTextToImage(text);
    this.editor.current.insertImage(img);
    this.emojiRef.current.close();
  }

  uploadImagePipe(image) {
    if (!image) {
      return;
    }

    if (Object.keys(this.state.images).length >= this.props.limitImage) {
      return this.props.onError(IMG_ERR_COUNT);
    }

    const { name, lastModified, size } = image;
    // 使用文件名、修改时间、尺寸 三元组作为缓存 key
    // 可以简单认为同一个设备中，三元组一样的文件，一定是同一个
    const imageId = `${name}||${size}||${lastModified}`;
    // 相同的图片已经存在，不再重复上传
    if (this.state.images[imageId]) {
      // console.log('图片重复:', imageId, '全部图片：', state.images);
      return this.props.onError(IMG_ERR_EXIST);
    }
    Promise.resolve()
      .then(() => this.readImage(image, imageId))
      .then(() => this.uploadImage(image, imageId));
  }

  onSelectImage(event) {
    const { state, props } = this;

    const images = Array.from(event.target.files);
    const existImageLength = Object.keys(state.images).length;

    if (images.length + existImageLength > props.limitImage) {
      return props.onError(IMG_ERR_COUNT);
    }

    if (images.some(checkSize)) {
      return props.onError(IMG_ERR_SIZE);
    }

    if (images.some(checkType)) {
      return props.onError(IMG_ERR_TYPE);
    }
    images.forEach((image) => {
      this.uploadImagePipe(image);
    });

    // 每次 input 读取完数据后，重置 form 表单
    // 否则 input 的 onChange 有时会不触发
    const formEle = findParentElement(event.target);
    formEle.reset();
  }

  /**
   * 读取图片并预览出来
   * */
  readImage(image, imageId) {
    const { state, props } = this;

    const fr = new FileReader();

    return new Promise((resolve, reject) => {
      // console.log('before FileReader');

      const onLoad = (event) => {
        // console.log('FileReader load:', event);

        const dataURL = event.target.result;
        this.setState(({ images }) => {
          images[imageId] = {
            id: imageId,
            progress: 0,
            dataURL,
          };
          return { images: Object.assign({}, images) };
        });

        resolve();
      };

      fr.addEventListener('load', onLoad, false);
      fr.addEventListener('error', reject, false);

      fr.readAsDataURL(image);

      // console.log('after FileReader readAsDataURL');
    });
  }

  /**
   * 上传图片并更新上传进度
   * 调用外部的实际接口，接口通过 props.onUploadImage 传递
   * */
  uploadImage(image, imageId) {
    const { state, props } = this;

    const fd = new FormData();

    // 这里type要根据场景分开来， 用props.formDataType 来控制吧
    fd.append('type', props.formDataType);
    fd.append('upload', image);

    // 更新上传进度
    const onProgress = (progressEvent) => {
      // console.log('progress:', progressEvent);
      const { total, loaded } = progressEvent;

      this.setState(({ images }) => {
        // 如果图片在上传过程被删了，不再更新进度
        if (typeof images[imageId] === 'undefined') {
          return;
        }

        let progress = ((loaded / total) * 100).toFixed(2);

        // 进度 100%，只表示上传完成了
        // 但此时还是收到后端但返回，所以进度要锁定在90%的值，便于视觉区分
        if (Number(progress) > 90) {
          progress = '90';
        }

        images[imageId] = Object.assign(images[imageId], { progress });

        return { images: Object.assign({}, images) };
      });
    };

    // 上传成功
    const onSuccess = (resp) => {
      this.setState(({ images }) => {
        // 如果图片在上传过程被删了，不再更新进度
        if (typeof images[imageId] === 'undefined') {
          return;
        }

        images[imageId] = Object.assign(images[imageId], {
          respInfo: resp.data,
          progress: '100',
        });

        return { images: Object.assign({}, images) };
      });
    };

    // 上传失败，删除图片
    const onFail = (error) => {
      this.onDelImage(imageId);
      return props.onError(error);
    };

    props
      .onUploadImage(fd, onProgress)
      .then(onSuccess)
      .catch(onFail);
  }

  /**
   * 删除指定图片
   * */
  onDelImage(targetId) {
    const { props } = this;

    this.setState(({ images }) => {
      delete images[targetId];
      return { images: Object.assign({}, images) };
    });
  }

  getPreviewImages() {
    const { state } = this;

    return Object.keys(state.images).map((id) => {
      const image = state.images[id];

      return {
        src: image.dataURL,
        id,
        progress: image.progress,
      };
    });
  }

  /**
   * 获取文本内容，去掉两端空格
   */
  getTextValue() {
    if (!this.editor.current) {
      // console.log('！Editor Ref 不存在');
      return '';
    }

    const value = this.editor.current.getValue();

    return getValue(value);
  }

  /**
   * 计算内容长度
   * @return {string|number|Error}
   */
  getTextLengthLimit(value) {
    const { props } = this;

    const diff = Number(props.limitText) - value.length;

    if (diff < 0) {
      return new Error(`超过了 ${Math.abs(diff)} 个字符`);
    }

    return diff;
  }

  /**
   * 判断提交按钮是否 disabled 态
   * @return {boolean}
   */
  getButtonDisabled(value, diff) {
    const { state, props } = this;
    if (props.disabled) {
      return true;
    }

    if (state.submitting) {
      return true;
    }

    if (!isNumber(diff)) {
      return true;
    }

    return false;
  }

  render() {
    const { props } = this;
    const { showSelectTopic } = this.props;
    const textValue = this.getTextValue();
    const textLengthLimit = this.getTextLengthLimit(textValue);
    const btnDisabled = this.getButtonDisabled(textValue, textLengthLimit);
    return (
      <div
        className={classNames('user_enter_form', { user_enter_disabled: props.disabled, user_enter_pc: props.isPc })}
      >
        <div className="text_outline">
          {props.topic && <Topic disabled topic={props.topic} />}
          {/* 没有外部传入话题，且产品打开了话题设置，才显示话题选择 */}
          {!props.topic && showSelectTopic && (
            <SelectTopic productId={this.productId} ref={this.selectTopicRef} defaultTopic={props.defaultTopic} />
          )}
          <Editor
            ref={this.editor}
            className="text_area text_area_maxH"
            disabled={props.disabled}
            placeholder={props.disabled ? '楼主关闭了评论功能' : props.placeholder}
            defaultValue={revertWordToImage(props.defaultPost)}
            onPasteTransform={(string, image) => {
              // 把 [表情] 转成 <img />
              let html = revertWordToImage(string);

              // 把 \n 转成 <br />
              html = revertBr(html);

              // 把 html 字符串插入到光标处
              insertHtml(html);

              // 处理剪切板中的图片
              this.uploadImagePipe(image);
            }}
            onChange={() => this.forceUpdate()}
          />

          <ImagePreview images={this.getPreviewImages()} onDel={(id) => this.onDelImage(id)} />
        </div>
        <div className="text_func">
          <div className="text_func_icon">
            <form className="form-wrapper-for-input-file">
              <BtnEmojiWithDropdown ref={this.emojiRef} hasParentEle={props.hasParentEle}>
                <EmojiPopup onSelectEmoji={(text, classname) => this.onSelectEmoji(text, classname)} />
              </BtnEmojiWithDropdown>

              <label className="btn-spark btn-upload" htmlFor={this.inputFileId}>
                <TcIconPicLine />
              </label>

              <input
                type="file"
                multiple={true}
                onChange={(event) => this.onSelectImage(event)}
                id={this.inputFileId}
              />

              <TextLimitTip>{textLengthLimit}</TextLimitTip>
            </form>
          </div>

          <button className="text_func_btn" onClick={(event) => this.onSubmit(event)} disabled={btnDisabled}>
            {props.textbtn}
          </button>

          {props.External}
        </div>
      </div>
    );
  }
}

UserEnter.propTypes = {
  defaultPost: PropTypes.string,
  defaultTopic: PropTypes.object,
  showSelectTopic: PropTypes.bool,
  limitImage: PropTypes.number,
  limitText: PropTypes.number,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onUploadImage: PropTypes.func,
  onError: PropTypes.func,
  External: PropTypes.element,
  isPc: PropTypes.bool,
  formDataType: PropTypes.string,
  placeholder: PropTypes.string,
  hasParentEle: PropTypes.bool,
  textbtn: PropTypes.string,
  activeTopic: PropTypes.object,
  children: PropTypes.shape({
    message: PropTypes.string,
  }),
  topic: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
};

UserEnter.defaultProps = {
  defaultPost: '',
  showSelectTopic: false,
  limitImage: 6,
  limitText: 500,
  disabled: false,
  isPc: false,
  formDataType: 'reply',
  onSubmit(value) {
    console.log(`onSubmit 未绑定, 最终提交的内容是：${value}`);
  },
  onUploadImage(fd, onProgress) {
    return Promise.resolve({ status: 0, data: {} });
  },
  onError(error) {
    console.log('onError 未绑定，错误详情', error);
  },
};

const TextLimitTip = (props) => {
  if (isError(props.children)) {
    return (
      <span className="text_func_num" style={{ color: '#EF382D' }}>
        {props.children.message}
      </span>
    );
  }

  return <span className="text_func_num">{props.children}</span>;
};
TextLimitTip.propTypes = {
  children: PropTypes.number,
};

export { UserEnter };
