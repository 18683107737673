import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TcIconEmojiLine } from 'components/IconSystem';
import { withDropdown } from '../../dropdown';

export const BtnEmoji = forwardRef((props, ref) => (
  <span
    className={classnames('btn-spark', 'btn-emoji', props.className)}
    onClick={props.onClick}
    onKeyPress={props.onClick}
    role="button"
    tabIndex="0"
    ref={ref}
  >
    <TcIconEmojiLine />
  </span>
));
BtnEmoji.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
BtnEmoji.displayName = 'BtnEmoji';
export const BtnEmojiWithDropdown = withDropdown(BtnEmoji);
